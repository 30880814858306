import Head from "next/head";
import Hero from "../components/hero";
import Navbar from "../components/navbar";
import SectionTitle from "../components/sectionTitle";
import { benefitOne } from "../components/data";
import Video from "../components/video";
import Benefits from "../components/benefits";
import Footer from "../components/footer";

const Home = () => {
  return (
    <>
      <Head>
        <title>BEAMRaiL: Modern Law Enforcement Software for Streamlined Patrol Operations</title>
        <meta
          name="description"
          content="BEAMRaiL: Modern Law Enforcement Software for Streamlined Patrol Operations"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navbar />
      <Hero />

      <SectionTitle>
        <span className="p-0">
          BEAMRaiL is revolutionizing law enforcement operations with cutting-edge AI technology. Our advanced tools empower agencies to concentrate on serving their communities rather than being bogged down by paperwork. By leveraging our solutions, agencies can streamline various administrative tasks, including report generation, evidence management, and workflow approvals, resulting in a reduction of operational overhead by up to 80%. This significant efficiency gain not only enhances productivity but also allows officers to dedicate more time to critical tasks such as community engagement, crime prevention, and investigative work.
        </span>
      </SectionTitle>

      <SectionTitle>
        <span className="p-0">
          Our platform is a highly secure AI-based records and operations management cloud software. BEAMRaiL utilizes AI-enhanced templates to assist officers and teams in completing operations and incident reports up to 80% faster. This advanced system ensures data integrity and security while significantly accelerating the reporting process, allowing law enforcement personnel to focus more on community service and critical operations.
        </span>
      </SectionTitle>

      <SectionTitle>
        <span className="p-0">
          With BEAMRaiL, law enforcement agencies can achieve greater accuracy, improved compliance, and enhanced operational effectiveness, ultimately fostering safer and more connected communities. Our technology supports agencies in reducing their administrative burden, improving the efficiency and effectiveness of their operations, and enhancing the overall quality of service provided to their communities.
        </span>
      </SectionTitle>
      <Benefits data={benefitOne} />
      <Video />
      <Footer />
    </>
  );
}

export default Home;